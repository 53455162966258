import React, { Component, Fragment } from "react"
import { CDN_DOMAIN } from "../config/cdn"
import ReactPlayer from "react-player"

class BSmart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openYoutubeVideo: false
        }
    }

    render() {
        return (
            <Fragment>
                <section
                    className="container-fluid"
                    style={{ marginTop: 60, background: "#FAFAFA", paddingTop: 40, paddingBottom: 40 }}
                >
                    <div className="row">
                        <div className="col-md-12">
                            <h2
                                style={{
                                    fontFamily:
                                        "Lato, Roboto, Helvetica, Arial, sans-serif",
                                    color: "black",
                                    margin: "auto",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: 30,
                                    marginBottom: 40
                                }}
                            >
                               Découvrez-nous sur l'Emission Capital
                            </h2>
                        </div>
                    </div>
                    {/*<div style={{ maxWidth: 800, margin: 'auto', width: '100%', marginTop: 40, position: "relative" }}>
                        {!this.state.openYoutubeVideo && (
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 10,
                                    top: "calc(50% - 45px)",
                                    left: "calc(50% - 45px)"
                                }}
                            >
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        this.setState({
                                            openYoutubeVideo: true
                                        })
                                    }}
                                >
                                    <img
                                        className="play-video"
                                        src={`${CDN_DOMAIN}/static/svg/ico-play.svg`}
                                        style={{
                                            height: 90,
                                            width: 90
                                        }}
                                        alt=""
                                    />
                                </a>
                            </div>
                        )}
                        {this.state.openYoutubeVideo && (
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 10,
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0
                                }}
                            >
                                <iframe
                                    maxWidth="800"
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/99KEBVlbk70?autoplay=1&feature=emb_logo"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                        )}
                        <img
                            src={`${CDN_DOMAIN}/static/videos/bsmart_1500.png`}
                            alt="B SMART - L'immobilier de commerce résiste-t-il ?"
                            style={{
                                width: "100%",
                                zIndex: 1
                            }}
                        />
                        </div>*/}
                        <div style={{ maxWidth: 800, margin: "auto", marginBottom: 20 }}>
                            <div className="player-wrapper">
                                <ReactPlayer
                                    controls
                                    playing
                                    className="react-player"
                                    style={{ margin: 'auto' }}
                                    light={`${CDN_DOMAIN}/static/videos/capital_m6.png`}
                                    url={`${CDN_DOMAIN}/static/videos/capital_m6.mp4`}
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>
                </section>
            </Fragment>
        )
    }
}

export default BSmart
