import React, { Component, Fragment } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Link from "next/link"
import { NextSeo } from "next-seo"
import getConfig from "next/config"
import { Carousel } from "react-responsive-carousel"
import { animateScroll as scroll } from "react-scroll"
import { responsiveWrapper } from "react-responsive-redux"
import Fade from "react-reveal/Fade"
import { isMobileOnly } from "react-device-detect"

import {
    showVideoPopup,
    setConfig,
    asyncLastProspectionsFetch
} from "../actions"

import UECHeader from "../components/UECHeader"
import UECFooter from "../components/UECFooter"
import MainBlockNew from "../components/MainBlockNew"
import TestimonialCard from "../components/TestimonialCard"
import ProCustomers from "../components/ProCustomers"
import BrandCustomers from "../components/BrandCustomers"
import LastProspections from "../components/LastProspections"
import UECProSubscribeDialog from "../components/UECProSubscribeDialog"
import BSmart from "../components/BSmart"
import Video from "../components/Video"
import PressRelease from "../components/PressRelease"
import brandsTestimonials from "../datas/brandsTestimonials"

import StyleBase from "../styles/base"
import kpis from "../datas/kpis"
import { CDN_DOMAIN } from "../config/cdn"
import { generateObfuscatedLink } from "../helpers/SEO"
import CrispBlock from "../components/CrispBlock"

const HideDesktop = responsiveWrapper({ query: `(max-width: 767px)` })
const HideForm = responsiveWrapper({ query: `(min-width: 768px)` })

class IndexPage extends Component {
    static async getInitialProps({ store, req }) {
        if (req) {
            store.dispatch(setConfig(req.config.publicRuntimeConfig))
            store.dispatch(
                await asyncLastProspectionsFetch(
                    req.config.publicRuntimeConfig,
                    { firebase: req.firebaseServer, firebase_second: req.firebaseServerSecond, redis: req.redis }
                )
            )
        }

        return { isMobileOnly }
    }

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            isMobileOnly: props.isMobileOnly
        }
    }

    handleClick = event => {
        // This prevents ghost click.
        event.preventDefault()

        this.setState({
            open: true,
            anchorEl: event.currentTarget
        })
    }

    handleRequestClose = () => {
        this.setState({
            open: false
        })
    }

    render() {
        const title = 'L’immobilier d’entreprise off-market : bureaux, locaux commerciaux, entrepôts, fonds de commerce à la location ou à la vente'
        const description = 'Accédez à des offres off-market de locaux à louer ou à vendre et découvrez les prix et loyers des bureaux, locaux commerciaux, entrepôts, fonds de commerce, terrains, pop-up stores et espaces de coworking partout en France'
        return (
            <Fragment>
                <NextSeo
                    titleTemplate="%s - UnEmplacement.com"
                    title={title}
                    description={description}
                    canonical={`https://${this.props.config.websiteDomain}`}
                    openGraph={{
                        url: `https://${
                            this.props.config.websiteDomain
                        }`,
                        title,
                        description
                    }}
                />
                <CrispBlock websiteID="5e7d0eae-1770-4c5c-8a0f-951e739365e4" />
                <StyleBase />
                <UECHeader config={this.props.config} />
                <UECProSubscribeDialog config={this.props.config} />
                <HideForm>
                    <div className="front">
                        <div
                            className="col-md-12"
                            style={{
                                padding: 0
                            }}
                        >
                            <MainBlockNew
                                fontSize={30}
                                width="100%"
                                minHeight={640}
                                marginBottom="70px"
                                marginTop="0px"
                                marginTop2="60px"
                                marginBottom2="inherit"
                                padding={30}
                                color1="white"
                                color2="white"
                                size1={30}
                                size2={16}
                                paddingRight={0}
                            />
                        </div>
                    </div>
                    <PressRelease />
                    <h2
                        style={{
                            fontFamily:
                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                            color: "#0db3ac",
                            margin: "auto",
                            textAlign: "center",
                            fontWeight: 900,
                            fontSize: 22,
                            marginBottom: 20
                        }}
                    >
                        En chiffres
                    </h2>
                    <div className="kpis row container-fluid mr-md-custom2">
                        {kpis.map((entry, i) => (
                            <Fade>
                                <div
                                    key={i}
                                    className="col"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            color: "#0db3ac",
                                            fontWeight: 900,
                                            fontSize: 25,
                                            marginBottom: 10,
                                            marginTop: 10,
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        {entry.value}
                                    </div>
                                    <div
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            color: "black",
                                            fontWeight: 500,
                                            fontSize: 14,
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        {entry.unit}
                                    </div>
                                </div>
                            </Fade>
                        ))}
                    </div>
                </HideForm>
                <HideDesktop>
                    <div className="row mr-md-custom2">
                        <div
                            className="col-md-12"
                            style={{
                                padding: 0,
                                paddingBottom: 20
                            }}
                        >
                            <MainBlockNew
                                fontSize={30}
                                width="100%"
                                marginBottom="50px"
                                marginTop="20px"
                                marginTop2="30px"
                                marginBottom2="30px"
                                marginTop3="20px"
                                textAlign="center"
                                marginTitleAuto
                                marginBottom3="0px"
                                paddingRight={0}
                            />
                        </div>
                    </div>
                    <PressRelease />
                </HideDesktop>
                <section
                    className="container-fluid mr-md-custom2"
                    style={{ marginTop: 30 }}
                >
                    <Fade>
                        <div className="row">
                            <div className="col-md-12">
                                <h2
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "#0db3ac",
                                        margin: "auto",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: 32
                                    }}
                                >
                                    Pourquoi UnEmplacement.com ?
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6" style={{ marginTop: 30 }}>
                                <div
                                    className="left_advantage"
                                    style={{ maxWidth: 400 }}
                                >
                                    <div
                                        className="row"
                                        style={{
                                            justifyContent: "center"
                                        }}
                                    >
                                        <div
                                            className="col-md"
                                            style={{
                                                maxWidth: 80,
                                                flex: "0 0 80px"
                                            }}
                                        >
                                            <img
                                                src={`${CDN_DOMAIN}/static/svg/ico-securise.svg`}
                                                alt=""
                                                style={{
                                                    width: 50,
                                                    margin: "auto"
                                                }}
                                            />
                                        </div>
                                        <h3
                                            className="col-md center-title"
                                            style={{
                                                fontFamily:
                                                    "Lato, Roboto, Helvetica, Arial, sans-serif",
                                                fontWeight: 900,
                                                color: "black",
                                                fontSize: 22,
                                                lineHeight: 1.1,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                margin: 0
                                            }}
                                        >
                                            Entrez dans un marché privé, <br />non visible sur internet
                                        </h3>
                                    </div>
                                    <div
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            fontSize: 14,
                                            color: "black",
                                            marginTop: 20,
                                            lineHeight: 1.3
                                        }}
                                    >
                                        <p>
                                            Avez-vous déjà vu un panneau “à vendre” ou “à louer” sur un local ou terrain bien placé ?
                                        </p>
                                        <p>
                                            Rarement, car ces informations sont trop sensibles pour être diffusées au grand public et seuls les professionnels spécialisés en immobilier d’entreprise ont accès à ces produits exclusifs.
                                        </p>
                                        <p>
                                            UnEmplacement.com est l'unique plateforme qui vous permet d’accéder rapidement ces dossiers confidentiels.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ marginTop: 30 }}>
                                <div
                                    className="right_advantage"
                                    style={{ maxWidth: 400 }}
                                >
                                    <div
                                        className="row"
                                        style={{
                                            justifyContent: "center"
                                        }}
                                    >
                                        <div
                                            className="col-md"
                                            style={{
                                                maxWidth: 80,
                                                flex: "0 0 80px"
                                            }}
                                        >
                                            <img
                                                src={`${CDN_DOMAIN}/static/svg/ico-informez.svg`}
                                                alt=""
                                                style={{
                                                    width: 50,
                                                    margin: "auto"
                                                }}
                                            />
                                        </div>
                                        <h3
                                            className="col-md center-title"
                                            style={{
                                                fontFamily:
                                                    "Lato, Roboto, Helvetica, Arial, sans-serif",
                                                fontWeight: 900,
                                                color: "black",
                                                fontSize: 22,
                                                lineHeight: 1.1,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                margin: 0
                                            }}
                                        >
                                            Informez 100% du marché,<br />en 1 clic
                                        </h3>
                                    </div>
                                    <div
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            fontSize: 14,
                                            color: "black",
                                            marginTop: 20,
                                            lineHeight: 1.3
                                        }}
                                    >
                                        <p>
                                            Aucun professionnel de l'immobilier ne détient l'intégralité des biens disponibles à la vente ou à la location sur son secteur.
                                        </p>
                                        <p>
                                            Trouver la perle rare demande du temps et de l'énergie. Pour maximiser vos chances de dénicher le bon emplacement, il est crucial de communiquer votre recherche à un maximum de professionnels.
                                        </p>
                                        <p>
                                            UnEmplacement.com vous fait gagner un temps précieux en diffusant votre recherche en un clic à l'ensemble des professionnels spécialisés selon leur zone d'activité.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </section>
                <section
                    className="testimonials"
                    style={{
                        background: "#0db3ac",
                        marginTop: 40,
                        paddingTop: 40,
                        paddingBottom: 60
                    }}
                >
                    <Fade>
                        <div className="container-fluid mr-md-custom2">
                            <div className="row">
                                <div className="col-md-12">
                                    <div
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            color: "white",
                                            margin: "auto",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            fontSize: 32,
                                            lineHeight: 1.2
                                        }}
                                    >
                                        <span className="hideForm">
                                            Enseignes, porteurs de projets,
                                            <br />
                                            indépendants...{" "}
                                        </span>
                                        Ils adorent.
                                    </div>
                                </div>
                            </div>
                            <HideForm>
                                <div className="row">
                                    {brandsTestimonials.map((testimonial, i) => {
                                        if (i < 2) {
                                            return (
                                                <div
                                                    key={i}
                                                    className="col-md-6"
                                                    style={{
                                                        marginTop: 50
                                                    }}
                                                >
                                                    <TestimonialCard
                                                        noTruncate
                                                        key={i}
                                                        className={`${
                                                            i === 0
                                                                ? "left"
                                                                : "right"
                                                        }_testimonial`}
                                                        brand={testimonial.brand}
                                                        brandLogo={
                                                            testimonial.brandLogo
                                                        }
                                                        firstname={
                                                            testimonial.firstname
                                                        }
                                                        lastname={
                                                            testimonial.lastname
                                                        }
                                                        position={
                                                            testimonial.position
                                                        }
                                                        testimonial={
                                                            testimonial.testimonial
                                                        }
                                                    />
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </HideForm>
                            <HideDesktop>
                                <div style={{ marginTop: 20, marginBottom: 10 }}>
                                    <Carousel
                                        showThumbs={false}
                                        showArrows={false}
                                        showStatus={false}
                                    >
                                        {[
                                            brandsTestimonials[5],
                                            ...brandsTestimonials
                                        ]
                                            .map((testimonial, i) => {
                                                if (i < 5) {
                                                    return (
                                                        <TestimonialCard
                                                            noTruncate
                                                            key={i}
                                                            style={{
                                                                marginLeft: 10,
                                                                marginRight: 10
                                                            }}
                                                            brand={
                                                                testimonial.brand
                                                            }
                                                            brandLogo={
                                                                testimonial.brandLogo
                                                            }
                                                            firstname={
                                                                testimonial.firstname
                                                            }
                                                            lastname={
                                                                testimonial.lastname
                                                            }
                                                            position={
                                                                testimonial.position
                                                            }
                                                            testimonial={
                                                                testimonial.testimonial
                                                            }
                                                            style={{
                                                                height: 340,
                                                                overflow: "hidden"
                                                            }}
                                                        />
                                                    )
                                                }

                                                return null
                                            })
                                            .filter(o => o)}
                                    </Carousel>
                                </div>
                            </HideDesktop>
                        </div>
                    </Fade>
                </section>
                <div style={{ position: "relative", textAlign: "center" }}>
                    {generateObfuscatedLink(
                        "/recommandations/enseignes",
                        "Voir toutes les recommandations",
                        "uec-button-line",
                        { width: 200, margin: "auto", top: -10 }
                    )}
                </div>
                <section
                    className="container-fluid mr-md-custom2"
                    style={{ marginTop: 30 }}
                >
                    <div className="row">
                        <div className="col-md-12">
                            <h2
                                style={{
                                    fontFamily:
                                        "Lato, Roboto, Helvetica, Arial, sans-serif",
                                    color: "#0db3ac",
                                    margin: "auto",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: 32
                                }}
                            >
                                Comment ça marche ?
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7" style={{ marginTop: 50 }}>
                            <figure>
                                <Fade left>
                                    <img
                                        src={`${CDN_DOMAIN}/static/laptop_screen.png`}
                                        alt=""
                                        style={{
                                            width: "100%"
                                        }}
                                    />
                                </Fade>
                            </figure>
                        </div>
                        <div
                            className="col-md-5"
                            style={{
                                marginTop: 50,
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <Fade>
                                <div className="text-block">
                                    <h3
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            fontWeight: 900,
                                            color: "black",
                                            fontSize: 28,
                                            lineHeight: 1.1
                                        }}
                                        className="center-title-no-margin"
                                    >
                                        Envoyez votre recherche
                                    </h3>
                                    <div
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            fontSize: 14,
                                            color: "black",
                                            marginTop: 20,
                                            lineHeight: 1.3
                                        }}
                                        className="center-title-no-margin"
                                    >
                                        <p>
                                            Décrivez précisément votre recherche en moins de 3 minutes, grâce à des critères sur-mesure définis avec des professionnels spécialisés en immobilier d'entreprise.
                                        </p>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className="row reverse-row" style={{ marginTop: 25 }}>
                        <div
                            className="col-md-8"
                            style={{
                                marginTop: 50,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Fade>
                                <div
                                    className="text-block"
                                    style={{ maxWidth: 495 }}
                                >
                                    <h3
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            fontWeight: 900,
                                            color: "black",
                                            fontSize: 28,
                                            lineHeight: 1.1
                                        }}
                                        className="center-title-no-margin"
                                    >
                                        Recevez des offres qualifiées
                                    </h3>
                                    <div
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            fontSize: 14,
                                            color: "black",
                                            marginTop: 20,
                                            lineHeight: 1.3
                                        }}
                                        className="center-title-no-margin"
                                    >
                                        <p>
                                            Notre équipe vérifie minutieusement chaque dossier pour s'assurer qu'il correspond parfaitement à vos critères de recherche. Fini les alertes mails inutiles qui vous submergent !
                                        </p>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        <div
                            className="col-md-4"
                            style={{ marginTop: 50, textAlign: "center" }}
                        >
                            <figure>
                                <Fade right>
                                    <img
                                        src={`${CDN_DOMAIN}/static/mobile_screen.png`}
                                        alt=""
                                        style={{
                                            maxWidth: 250,
                                            margin: "auto"
                                        }}
                                    />
                                </Fade>
                            </figure>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 25 }}>
                        <div className="col-md-7" style={{ marginTop: 50 }}>
                            <figure>
                                <Fade left>
                                    <img
                                        src={`${CDN_DOMAIN}/static/pad_screen.png`}
                                        alt=""
                                        style={{
                                            width: "100%"
                                        }}
                                    />
                                </Fade>
                            </figure>
                        </div>
                        <div
                            className="col-md-5"
                            style={{
                                marginTop: 50,
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <Fade>
                                <div
                                    className="text-block"
                                    style={{ marginLeft: 0 }}
                                >
                                    <h3
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            fontWeight: 900,
                                            color: "black",
                                            fontSize: 28,
                                            lineHeight: 1.1
                                        }}
                                        className="center-title-no-margin"
                                    >
                                        Contactez directement les professionnels
                                    </h3>
                                    <div
                                        style={{
                                            fontFamily:
                                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                                            fontSize: 14,
                                            color: "black",
                                            marginTop: 20,
                                            lineHeight: 1.3
                                        }}
                                        className="center-title-no-margin"
                                    >
                                        <p>
                                            Si votre recherche est urgente, voici un moyen d'aller encore plus vite. Une fois votre recherche envoyée, vous devenez membre et accédez aux coordonnées directes des 2 000 brokers et foncières, triés par zone de travail. Contactez-les directement pour booster votre prospection et dénicher rapidement la perle rare.
                                        </p>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </section>
                <section
                    className="container-fluid mr-md-custom2"
                    style={{ marginTop: 60 }}
                >
                    <Fade>
                        <div className="row">
                            <div className="col-md-12">
                                <h2
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "black",
                                        margin: "auto",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: 32
                                    }}
                                >
                                    Les avantages
                                </h2>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: 25 }}>
                            <div
                                className="col-md-4"
                                style={{
                                    marginTop: 50,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}
                            >
                                <img
                                    src={`${CDN_DOMAIN}/static/svg/ico-temps.svg`}
                                    alt=""
                                    style={{
                                        width: 50,
                                        margin: "auto"
                                    }}
                                />
                                <div
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        textAlign: "center",
                                        marginTop: 10,
                                        marginBottom: 8
                                    }}
                                >
                                    Gain de temps
                                </div>
                                <div
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "black",
                                        fontSize: 14,
                                        textAlign: "center",
                                        lineHeight: 1.3,
                                        marginLeft: 40,
                                        marginRight: 40
                                    }}
                                >
                                    Concentrez-vous sur le développement de vos
                                    ventes ou de votre projet plutôt que de perdre
                                    votre temps à prospecter à l'aveugle.
                                </div>
                            </div>
                            <div
                                className="col-md-4"
                                style={{
                                    marginTop: 50,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}
                            >
                                <img
                                    src={`${CDN_DOMAIN}/static/svg/ico-a-jour.svg`}
                                    alt=""
                                    style={{
                                        width: 50,
                                        margin: "auto"
                                    }}
                                />
                                <div
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        textAlign: "center",
                                        marginTop: 10,
                                        marginBottom: 8
                                    }}
                                >
                                    Relances automatiques
                                </div>
                                <div
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "black",
                                        fontSize: 14,
                                        textAlign: "center",
                                        lineHeight: 1.3,
                                        marginLeft: 40,
                                        marginRight: 40
                                    }}
                                >
                                    Relancez une fois par mois votre recherche en un
                                    clic et rappelez aux 2 000 professionnels que
                                    vous êtes toujours en recherche active.
                                </div>
                            </div>
                            <div
                                className="col-md-4"
                                style={{
                                    marginTop: 50,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}
                            >
                                <img
                                    src={`${CDN_DOMAIN}/static/svg/ico-check.svg`}
                                    alt=""
                                    style={{
                                        width: 50,
                                        margin: "auto"
                                    }}
                                />
                                <div
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        textAlign: "center",
                                        marginTop: 10,
                                        marginBottom: 8
                                    }}
                                >
                                    Service de qualité
                                </div>
                                <div
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "black",
                                        fontSize: 14,
                                        textAlign: "center",
                                        lineHeight: 1.3,
                                        marginLeft: 40,
                                        marginRight: 40
                                    }}
                                >
                                    Notre équipe vérifie que chaque proposition
                                    corresponde à vos critères et bloque les
                                    démarchages commerciaux.
                                </div>
                            </div>
                        </div>
                    </Fade>
                </section>
                <section
                    className="container-fluid mr-md-custom2"
                    style={{ marginTop: 80 }}
                >
                    <Fade>
                        <div className="row">
                            <div className="col-md-8">
                                <Video />
                            </div>
                            <div
                                className="col-md-4 padding-left-event-block"
                                style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 32,
                                        marginTop: 10,
                                        marginBottom: 30,
                                        lineHeight: 1.1
                                    }}
                                    className="center-title"
                                >
                                    Participez à des petits déjeuners privés
                                </div>
                                <div
                                    style={{
                                        fontFamily:
                                            "Lato, Roboto, Helvetica, Arial, sans-serif",
                                        color: "black",
                                        fontSize: 14,
                                        lineHeight: 1.3
                                    }}
                                    className="center-title-no-margin"
                                >
                                    <p>
                                        Chaque trimestre nous invitons nos membres à
                                        échanger avec les décisionnaires clés de
                                        plus de 1000 sociétés spécialisées en
                                        immobilier commercial pour bénéficier de
                                        leur expertise et de leur réseau.
                                    </p>
                                    <p>
                                        Dans un cadre convivial et qualitatif vous
                                        aurez l'opportunité d'échanger en
                                        tête-à-tête sur votre projet et recherches,
                                        mais surtout de présenter votre concept à
                                        cet auditoire ultra-qualifié.
                                    </p>
                                    <p>
                                        Pour devenir membre, c’est simple, il suffit
                                        d’envoyer votre recherche.
                                    </p>
                                    <a
                                        target="_blank"
                                        href="https://blog.unemplacement.com/petits-dejeuners/"
                                        onClick={() => scroll.scrollToTop()}
                                        className="uec-button"
                                        style={{
                                            width: 140,
                                            margin: "auto",
                                            marginTop: 20
                                        }}
                                    >
                                        En savoir plus
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </section>
                <Fade>
                    <BSmart
                        config={this.props.config}
                        isMobileOnly={this.state.isMobileOnly}
                    />
                </Fade>
                <Fade>
                    <LastProspections
                        config={this.props.config}
                        isMobileOnly={this.state.isMobileOnly}
                    />
                </Fade>
                <section
                    className="container-fluid mr-md-custom2"
                    style={{ marginTop: 60 }}
                >
                    <div className="row" style={{ marginLeft: 0 }}>
                        <div className="col-md-12">
                            <h2
                                style={{
                                    fontFamily:
                                        "Lato, Roboto, Helvetica, Arial, sans-serif",
                                    color: "black",
                                    margin: "auto",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    fontSize: 32
                                }}
                                className="center-title-bottom"
                            >
                                2 000 professionnels
                                <br />
                                qualifiés à votre écoute
                            </h2>
                            <div
                                className="real-estate-desc-block"
                                style={{
                                    fontFamily:
                                        "Lato, Roboto, Helvetica, Arial, sans-serif",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: 1.3,
                                    fontWeight: 500,
                                    width: "100%",
                                    maxWidth: 500,
                                    marginTop: 25
                                }}
                            >
                                Agents immobiliers, mandataires, foncières,
                                centres commerciaux, gares, ils sont tous
                                présents sur UnEmplacement.com pour vous
                                accompagner dans votre recherche de
                                l’emplacement idéal.
                            </div>
                        </div>
                    </div>
                    <ProCustomers />
                    <div className="row" style={{ marginTop: 15 }}>
                        <div className="col" style={{ textAlign: "center" }}>
                            <Link href="/professionnels-immobilier">
                                <a
                                    className="uec-button"
                                    style={{ width: 200, margin: "auto" }}
                                >
                                    Je suis un professionnel
                                </a>
                            </Link>
                        </div>
                    </div>
                </section>
                <BrandCustomers marginTop={60} />
                <UECFooter config={this.props.config} />
            </Fragment>
        )
    }
}

const mapStateToProps = ({ homepage, config }) => {
    const { videoPopupOpened } = homepage

    return {
        videoPopupOpened,
        config: {
            ...config.config,
            ...(typeof getConfig() !== "undefined"
                ? getConfig().publicRuntimeConfig
                : {})
        }
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ showVideoPopup }, dispatch)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexPage)
